@import 'node_modules/foundation-sites/scss/foundation';
@import 'scss/settings';

@include foundation-everything;

.menu-icon::after {
  background: $primary-color;
  box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
}

@mixin header {
  font-family: 'Courgette', cursive;
  color: #f7c1d4;//$primary-color;
}
@mixin grid-x-padd {
  padding-top: 2em;
  padding-bottom: 2em;
  @include breakpoint(medium) {
    padding-top: 4em;
    padding-bottom: 4em;
  }
}

span.logo {
  @include header;
  font-size: 1.16em;
  &::after {
    content: 'Lustfyllda jobb';
  }
  &.white {
    color: $white !important;
  }
}
.top-bar {
  span.logo {
    font-size: 4em;
    position: absolute;
  }
  .dropdown.menu > li > a {
    background: transparent;
  }
  .menu {
    font-weight: bold;
    flex-direction: column;
    @include breakpoint(medium) {
      flex-direction: row;
      a.active {
        color: $white;
      }
    }
  }
}
.title-bar-title {
  @include header;
  font-size: 1.5em;
  margin-left: calc(50% - 7ch);
}

.hero {
  &.cover {
    min-height: calc(100vh - 50px);
    @include breakpoint(medium) {
      min-height: 100vh;
    }
  }
  &:not(.cover) {
    @include grid-x-padd;
  }
  @include breakpoint(medium) {
    margin-top: -55px;
  }
  background-size: cover;
  &.about {
    background-position: 50% 70%;
    background-image: url('/img/about-hero.jpg');
    @include breakpoint(landscape) {
      background-position: center;
    }
  }
  &.telesex {
    background-position: 50% 35%;
    background-image: url('/img/telesex-hero.jpg');
  }
  &.thanks {
    background-position: 50% 50%;
    background-image: url('/img/thanks-hero.jpg');
  }
  &.operator-job {
    background-position: 50% 76%;
    background-image: url('/img/operator-hero.jpg');
  }
  &.voice-talent-job {
    background-position: 50% 70%;
    background-image: url('/img/voice-talent-hero.jpg');
  }
  &.scribe-job {
    background-position: 50% 70%;
    background-image: url('/img/scribe-hero.jpg');
  }
  &.index {
    background-position: center;
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url('/img/index-hero.jpg');
    @include breakpoint(landscape) {
      background-position: top;
    }
  }
  .foo {
    position: relative;
    top: calc(50vh - 10em);
    font-weight: bold;
    color: $white;
    a {
      font-size: 1.67em;
    }
  }
  h1, h2 {
    @include header;
    color: $white;
    text-align: center;
    margin-top: 0.5rem;
    @include breakpoint(medium) {
      margin-top: 2rem;
    }
    @include breakpoint(portrait) {
      margin-top: 1rem;
    }
  }
}

.grid-x.padd {
  @include grid-x-padd;
}

.footer {
  background-size: cover;
  background-position: center;
  background-color: $primary-color;
  background-image: url('/img/footer.jpg');
  color: $white;
  font-weight: bold;
  padding: 2rem 0;
  @include breakpoint(medium) {
    padding: 4rem 0;
  }
  ul {
    list-style: none;
  }
  p.title {
    margin: 0;
    text-decoration: underline;
  }
  a {
    color: $white;
    font-weight: normal;
  }
}
